.main {
  display: flex;
  flex-flow: column;
  height: 100%; }
  .main.default-bg {
    background: url(../../images/default_housing_bg.png); }
  .main.intro-bg {
    background: url(../../images/intro-bg.png) no-repeat center center;
    background-size: cover; }
  .main.housing-special-bg {
    background: url(../../images/city_hall_bg.jpg) no-repeat center center;
    background-size: cover; }
  .main.beachside-bg {
    background: url(../../images/beachside_bg.jpg) no-repeat center center;
    background-size: cover; }
  .main.sm-bg {
    background: url(../../images/sm-bg.jpg) no-repeat center center;
    background-size: cover; }
  .main.yellow-bg {
    background-color: #FED57C; }
  .main.blue-bg {
    background-color: #adccf0; }
  .main.train-bg {
    background: url(../../images/train-bg.jpg) no-repeat center center;
    background-size: cover; }
  .main.red-bg {
    background-color: #F5BFAE; }
  .main.white-bg {
    background-color: #FFFFFF; }
  .main.sidewalk-bg {
    background: url(../../images/sidewalk-bg.jpg) no-repeat center center;
    background-size: cover; }
  .main.college-bg {
    background: url(../../images/college-bg.jpg) no-repeat center center;
    background-size: cover; }
  .main.green-bg {
    background-color: #7EC2B7; }
  .main.transportation-special-bg {
    background: url(../../images/storefront_bg.png) no-repeat center center;
    background-size: cover; }
  .main.park-bg {
    background: url(../../images/park-bg.jpg) no-repeat center center;
    background-size: cover; }
  .main.sm-2-bg {
    background: url(../../images/sm-2.jpg) no-repeat center center;
    background-size: cover; }
  .main .coin-status {
    background: white;
    border-bottom-left-radius: 29px;
    border-top-left-radius: 29px;
    height: 46px;
    margin-left: 15px;
    margin-top: 10px;
    padding: 5px 20px;
    position: absolute;
    right: 0px;
    width: 92px;
    z-index: 1;
    -webkit-box-shadow: -1px 2px 5px 0px #24364d;
    -moz-box-shadow: -1px 2px 5px 0px #24364d;
    box-shadow: -1px 2px 5px 0px #24364d; }
    .main .coin-status .coin-img {
      width: 20px;
      height: 20px;
      margin-top: 4px;
      margin-left: 40px; }
    .main .coin-status h4 {
      margin-bottom: 0px;
      float: left; }
    .main .coin-status p {
      font-size: 11px !important;
      position: absolute;
      margin-top: 20px; }
    .main .coin-status img {
      position: absolute;
      margin-left: 6px;
      top: 2px; }
  .main .content-box {
    background: white;
    border-radius: 20px;
    margin-top: 150px;
    padding: 37px;
    max-width: 668px;
    margin-left: auto;
    margin-right: auto; }
    @media only screen and (max-width: 768px) {
      .main .content-box {
        margin-left: 10px;
        margin-right: 10px; } }
    .main .content-box h1 {
      text-align: center; }
    .main .content-box.bottom {
      position: absolute;
      bottom: 0px;
      left: 0px;
      right: 0px;
      margin-top: 0px;
      border-top-left-radius: 29px;
      border-top-right-radius: 29px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px; }
    .main .content-box .cb-holder {
      position: relative; }
      .main .content-box .cb-holder .person {
        width: 100px;
        height: 107px;
        position: absolute;
        right: -25px;
        top: -85px; }
  .main .forma {
    height: 100%; }
  .main .btn-holder {
    width: 100%;
    margin-top: 40px;
    text-align: center; }
  @media only screen and (max-width: 480px) {
    .main .btn-holder-bottom {
      position: fixed;
      width: 100%;
      text-align: center;
      bottom: 20px; } }
  @media only screen and (min-width: 480px) {
    .main .btn-holder-bottom {
      width: 100%;
      text-align: center;
      margin-top: 30px; } }
