#custom-header {
  background-color: #24364d;
  height: 70px;
  padding-top: 20px; }
  #custom-header.extended {
    height: 100px; }
  #custom-header p {
    color: white;
    text-align: center; }
  #custom-header .title-h3 {
    color: white;
    text-align: center;
    margin-bottom: 5px;
    margin-top: 0; }
  #custom-header .back-btn {
    padding-top: 5px;
    color: white;
    float: left;
    position: absolute;
    padding-left: 15px; }
